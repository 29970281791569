

import { defineComponent, ref } from "vue"
import { ErrorMessage, Field, Form } from "vee-validate"
import * as Yup from "yup"
import Swal from "sweetalert2/dist/sweetalert2.js"
import ApiService from "@/core/services/ApiService"
import store from "@/store"
import { Modal } from "bootstrap"
import { Mutations } from "@/store/enums/StoreEnums"
import { displayStatus } from "@/core/helpers/displayStatus"

// import _ from "lodash"

export interface Message {
  status: number;
  message: string;
}

export default defineComponent({
  name: "Add Connection Modal",
  props: {
    modalId: String,
  },
  emits: ['refreshData'],
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  mounted() {
    window.addEventListener('message', this.handleMessage);
  },
  beforeUnmount() {
    window.removeEventListener('message', this.handleMessage);
  },
  setup() {
    let submitAddAgencyAnalyticsRequest = ref<HTMLElement | null>(null)
    let submitGoHighLevelRequest = ref<HTMLElement | null>(null)

    return { submitAddAgencyAnalyticsRequest, submitGoHighLevelRequest }
  },
  data() {
    const addConnectionType = ''
    const accountNum = store.getters.activeAccountInfo.id
    const connectionTypes = [
      // @TODO add GA
      // {
      //   type: "ga", name: "Google Analytics"
      // },
      { type: "aa", name: "Agency Analytics" },
      { type: "ghl", name: "Go High Level" }
    ]


    const addAgencyAnalyticsFormValidator = Yup.object().shape({
      campaign_id: Yup.string().required().label("Campaign ID"),
      friendly_name: Yup.string().required().label("Friendly Name")
    })

    const addGoHighLevelFormValidator = Yup.object().shape({
      client_id: Yup.string().required().label("Client ID"),
      client_secret: Yup.string().required().label("Client Secret"),
      location_id: Yup.string().label("Location ID")
    })

    const agencyAnalytics = {
      campaign_id: '',
      friendly_name: ''
    }

    const goHighLevel = {
      client_id: '',
      client_secret: '',
      location_id: ''
    }

    const popupWindow: any = null


    return {
      connectionTypes,
      addConnectionType,
      addAgencyAnalyticsFormValidator,
      addGoHighLevelFormValidator,
      agencyAnalytics,
      goHighLevel,
      accountNum,
      popupWindow,
      receivedMessage: {} as Message,
    }
  },
  methods: {
    openPopUp(resp) {
      const popupUrl = new URL(resp.meta.go_high_level_url, window.location.origin) // URL of the popup page
      const params = new URLSearchParams({
        client_id: resp.data.attributes.client_id,
        client_secret: this.goHighLevel.client_secret,
        response_type: "code",
        redirect_uri: process.env.VUE_APP_FOOTPRINT_API_ENDPOINT.includes("staging") ? "https://control.hosting.hlmtech.com/rx/hl-integration/callback" : "https://dh7qh4dr3kl2o.cloudfront.net/rx/hl-integration/callback",
        guid: resp.data.id,
        accountNum: resp.data.attributes.pk.split("#").pop(),
        scope: "contacts.readonly contacts.write"
      })
      popupUrl.search = params.toString()

      // Open the popup window
      this.popupWindow = window.open(popupUrl.toString(), 'popupWindow', 'width=600,height=600')

      // Check if popup was blocked
      if (!this.popupWindow) {
        alert('Popup was blocked. Please allow popups for this site.')
      }
    },
    async addAgencyAnalytics() {

      if (this.submitAddAgencyAnalyticsRequest) {
        // Activate indicator
        this.submitAddAgencyAnalyticsRequest.setAttribute("data-kt-indicator", "on")

        let response = await this.sendRequest()

        if (response?.status == 201) {
          await Swal.fire({
            text: response.message,
            icon: "success",
            confirmButtonText: "Ok",
            buttonsStyling: false,
            customClass: {
              confirmButton: "btn btn-light-primary"
            }
          })
          this.submitAddAgencyAnalyticsRequest.removeAttribute("data-kt-indicator")
          await this.closeModal(this.$props.modalId)
          this.initForm()
          this.$emit("refreshData")
          await displayStatus(response.status_check)
        } else {
          await Swal.fire({
            text: response?.message,
            icon: "error",
            confirmButtonText: "Ok",
            buttonsStyling: false,
            customClass: {
              confirmButton: "btn btn-light-primary"
            }
          })
          this.submitAddAgencyAnalyticsRequest.removeAttribute("data-kt-indicator")
          await this.closeModal(this.$props.modalId)
        }

      }
    },
    async addGoHighLevel() {

      if (this.submitGoHighLevelRequest) {
        // Activate indicator
        this.submitGoHighLevelRequest.setAttribute("data-kt-indicator", "on")

        let resp = await this.sendRequest()

        if (resp?.status == 500) {
          await Swal.fire({
            text: resp.message,
            icon: "error",
            confirmButtonText: "Ok",
            buttonsStyling: false,
            customClass: {
              confirmButton: "btn btn-light-primary"
            }
          })
          this.submitGoHighLevelRequest.removeAttribute("data-kt-indicator")
          await this.closeModal(this.$props.modalId)
        }

      }
    },
    async sendRequest() {

      ApiService.setHeader()

      if (this.addConnectionType == 'aa') {
        let addAgencyAnalyticsResponse
        try {
          addAgencyAnalyticsResponse = await ApiService.post(`/accounts/${this.accountNum}/agency-analytics`, { data: this.agencyAnalytics })
        } catch (e) {
          console.error("Problem fetching update user response", e)
          return { message: "error adding agency analytics" }
        }

        if (addAgencyAnalyticsResponse.data) {

          store.commit(Mutations.UPDATE_ACTIVE_ACCOUNT_RELATIONSHIP, {
            name: "agencyAnalytics",
            data: { id: addAgencyAnalyticsResponse.data.data.id, ...addAgencyAnalyticsResponse.data.data.attributes }
          })

          return {
            status: addAgencyAnalyticsResponse.status,
            message: "created agency analytics successfully",
            status_check: addAgencyAnalyticsResponse.data.meta.status_check
          }

        }
      } else if (this.addConnectionType == 'ghl') {
        let addGoHighLevelResponse
        console.log("what do we send the first time to GHL", this.goHighLevel)
        try {
          addGoHighLevelResponse = await ApiService.post(`/accounts/${this.accountNum}/go-high-level`, { data: this.goHighLevel })
        } catch (e) {
          console.error("Problem fetching update user response", e)
          let message = e.response.data.errors[0].detail ? e.response.data.errors[0].detail : "error adding go high level connection"
          return { status: 500, message: message }
        }

        if (addGoHighLevelResponse.data.meta.go_high_level_url) {

          store.commit(Mutations.SET_GHL_INTEGRATION, {
            client_id: addGoHighLevelResponse.data.data.attributes.client_id,
            client_secret: this.goHighLevel.client_secret,
            accountNum: addGoHighLevelResponse.data.data.attributes.pk.split("#").pop(),
            guid: addGoHighLevelResponse.data.data.id,
            redirect_uri: "https://localhost:8080/rx/hl-integration/callback"
          })

          this.openPopUp(addGoHighLevelResponse.data)

        }
      }

    },
    async closeModal(id) {
      const modalId = String(id)
      const modalElement = document.getElementById(modalId)
      const modalInstance = Modal.getInstance(modalElement)
      modalInstance.hide()
    },
    async handleMessage(event) {
      // Check the origin of the message for security
      if (event.origin !== window.location.origin || event.origin !== "https://dh7qh4dr3kl2o.cloudfront.net") return;

      // Handle the data received from the popup window
      if (event.data.message == "success") {
        // do success thing
        this.receivedMessage = {
          status: 201,
          message: "created GHL integration successfully",
        }

        await Swal.fire({
          text: this.receivedMessage.message,
          icon: "success",
          confirmButtonText: "Ok",
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-light-primary"
          }
        })
        if (this.submitAddAgencyAnalyticsRequest) this.submitAddAgencyAnalyticsRequest.removeAttribute("data-kt-indicator")
        await this.closeModal(this.$props.modalId)
        this.initForm()

        // add GHL integration
        store.commit(Mutations.UPDATE_ACTIVE_ACCOUNT_RELATIONSHIP, {
          name: "goHighLevels",
          data: { id: event.data.id, ...event.data.data }
        })
        this.$emit("refreshData")

      } else if (event.data.message == "fail") {
        // do fail things
        this.receivedMessage = {
          status: 500,
          message: "did not create GHL integration successfully",
        }

        await Swal.fire({
          text: this.receivedMessage.message,
          icon: "error",
          confirmButtonText: "Ok",
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-light-primary"
          }
        })
        if (this.submitAddAgencyAnalyticsRequest) this.submitAddAgencyAnalyticsRequest.removeAttribute("data-kt-indicator")
        await this.closeModal(this.$props.modalId)

      }


    },
    initForm() {
      this.addConnectionType = ""
      this.agencyAnalytics = {
        campaign_id: '',
        friendly_name: ''
      }
      this.goHighLevel = {
        client_id: '',
        client_secret: '',
        location_id: ''
      }
    },
  },
});
